import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { toCamel, toPascal } from 'utils/caseConverter';
import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';

import Panel from 'components/Panel';
import { CompanyNameContext } from 'components/CompanyNameContext';
import AppLoadingBar from 'components/AppLoadingBar';
import CastingForm from 'components/CastingForm';

import RegistrationSuccess from 'containers/RegistrationSuccess';
import {
  getRegistrationFormData,
  getRegistrationFormDataPreview,
  register,
  uploadPhoto,
  uploadVideo,
  registerPreview
} from 'actions/registration';

import { editImage, deleteImage, updateSortOrder, useImageAsThumbnail } from 'actions/imageEdit';

import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';
import { filesUploadValidationTypes, defaultFilesLimit } from 'utils/validation';

import './RegistrationForm.scss';

const initialState = {
  registrationData: null,
  registrationSuccessData: null,
  registeredSuccessfully: false,
  isSubmitting: false,
  captchaToken: null,
  showValidation: false,
  uploadProgress: 0,
  startProgress: false,
  loaded: [],
  showEmailValidationError: false
};

const SESSION_HAS_ENDED_ERROR_MESSAGE =
  'Your upload session has ended. Please try again and fill in your details in less than one hour.';

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
    this.formik = React.createRef();

    this.state = { ...initialState };
    
  }

  removeUnloadHandle() {
    window.onbeforeunload = null;
  }

  componentDidMount() {
    const { match, isPreview } = this.props;

    startLoadingBar(this.LoadingBar);

    if (isPreview) {
      getRegistrationFormDataPreview(match.params.sharedKey, match.params.profileId)
        .then((response) => this.handleRegistrationFormDataResponse(response))
        .catch(() => {
          endLoadingBar(this.LoadingBar);
          toast.error(GLOBAL_ERROR_MESSAGE);
        });
      return;
    }

    getRegistrationFormData(match.params.sharedKey, match.params.profileId)
      .then((response) => this.handleRegistrationFormDataResponse(response))
      .catch(() => {
        endLoadingBar(this.LoadingBar);
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  }

  reCaptchaChanged = (token) => {
    this.setState(
      {
        reCaptchaValidated: !!token,
        captchaToken: token
      },
      () => {
        if (token) {
          this.formik.current.submitForm();
        }
      }
    );
  };

  handleRegistrationFormDataResponse = (response) => {
    endLoadingBar(this.LoadingBar);

    if (response && response.status === 200) {
      if (response.payload.Errors && response.payload.Errors.length) {
        var error = response.payload.Errors[0];
        toast.error(error);
      }

      if (response.payload.Successes && response.payload.Successes.length) {
        var success = response.payload.Successes[0];
        toast.success(success);
      }

      this.setState({
        registrationData: response.payload.Data
      });
    } else {
      toast.error(GLOBAL_ERROR_MESSAGE);
    }
  };

  resetForm = () => {
    const { resetForm } = this.props;

    this.setState(
      {
        ...initialState
      },
      () => {
        if (resetForm) {
          resetForm();
        }
      }
    );
  };

  previewSuccess = () => {
    const { match } = this.props;

    startLoadingBar(this.LoadingBar);

    registerPreview(match.params.sharedKey, match.params.profileId)
      .then((response) => {
        endLoadingBar(this.LoadingBar);

        this.setState({
          isSubmitting: false
        });

        if (!response || response.status !== 200) {
          toast.error(GLOBAL_ERROR_MESSAGE);
          return;
        }

        if (response.payload.Successes && response.payload.Successes.length) {
          var success = response.payload.Successes[0];
          toast.success(success);
        }

        if (response.payload.Errors && response.payload.Errors.length) {
          var error = response.payload.Errors[0];
          toast.error(error);
        } else {
          this.setState({
            registrationSuccessData: response.payload.Data,
            registeredSuccessfully: true
          });
        }
      })
      .catch(() => {
        endLoadingBar(this.LoadingBar);
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  };

  previewSubmit = (values) => {
    const { isSubmitting, captchaToken } = this.state;

    if (!captchaToken) {
      this.recaptchaRef.current.execute();
      return;
    }

    values.CaptchaToken = captchaToken;

    if (isSubmitting) {
      return;
    }

    this.setState({
      isSubmitting: true
    });

    this.previewSuccess();
    this.removeUnloadHandle();
    return;
  };

  submit = (values) => {

    const { match, isPreview } = this.props;

    if (isPreview) {
      this.previewSubmit(values);
      return;
    }

    const { isSubmitting, captchaToken } = this.state;

    if (!captchaToken) {
      this.recaptchaRef.current.execute();
      return;
    }

    values.CaptchaToken = captchaToken;

    if (isSubmitting) {
      return;
    }

    this.setState({
      isSubmitting: true
    });

    startLoadingBar(this.LoadingBar);

    if(this.state.showEmailValidationError){
      values.isForced = true;
      
    }

    register(match.params.sharedKey, match.params.profileId, {
      ...values
    })
      .then((response) => {
        endLoadingBar(this.LoadingBar);

        this.setState({
          isSubmitting: false
        });

        if (!response || response.status !== 200) {
          toast.error(GLOBAL_ERROR_MESSAGE);
          return;
        }

        if (response.payload.Errors && response.payload.Errors.length) {
          var error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        if(response.payload.Data.ShowEmailValidation){
          this.setState({
            showEmailValidationError: true
          });

          this.recaptchaRef.current.reset();
          this.setState({
            captchaToken: null
          });
        
          return;
        }

        if (response.payload.Successes && response.payload.Successes.length) {
          var success = response.payload.Successes[0];
          toast.success(success);
        }

        this.removeUnloadHandle();
        this.setState(
            {
              registrationSuccessData: response.payload.Data,
              registeredSuccessfully: true
            },
            () => {
              window.scrollTo(0, 0);
            }
          );


      })
      .catch(() => {
        endLoadingBar(this.LoadingBar);
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  };

  onPhotoSelect =
    (validationId) =>
    async (attachments = [], isFileImage = () => {}) => {
      const { match, isPreview } = this.props;
      if (isPreview) {
        return;
      }
      if (!attachments.length && validationId !== 0) {
        toast.error(filesUploadValidationTypes[validationId].limitMessage);
        return [];
      } else if (!attachments.length && validationId === 0) {
        toast.error(defaultFilesLimit.limitMessage);
        return [];
      }

      this.setState({ startProgress: true });

      const totalFileSize = attachments.reduce((totalSize, file) => totalSize + file.size, 0);
      const uploadedFiles = [];
      await Promise.all(
        attachments.map(async (file, index) => {
          try {
            const response = await uploadPhoto(
              this.state.registrationData.CatalogService,
              this.state.registrationData.AccessToken,
              this.props.match.params.sharedKey,
              this.state.registrationData.ActorId,
              this.state.registrationData.CropEnabled,
              {
                files: file
              },
              match.params.profileId,
              (progressEvent) => {
                this.setState((prevState) => {
                  const newLoaded = prevState.loaded;
                  newLoaded[index] = progressEvent.loaded;

                  const percentCompleted =
                    (newLoaded.reduce((totalSize, size) => totalSize + size, 0) * 100) / totalFileSize;

                  this.setState({ uploadProgress: Number(percentCompleted.toFixed(0)) });
                  return newLoaded;
                });
              }
            );

            if (response?.payload && response?.status === 200) {
              if (response.payload.Successes?.length) {
                const success = response.payload.Successes[0];
                toast.success(success);
              }

              if (response.payload.Errors?.length) {
                const error = response.payload.Errors[0];
                toast.error(error);
              } else {
                uploadedFiles.push({
                  ...response.payload,
                  isPhoto: isFileImage(file)
                });
              }
            } else if (response?.payload?.ModelState?.Error?.length > 0) {
              const error = response.payload.ModelState.Error[0];
              if (error.startsWith('You can upload up to') && validationId !== 0) {
                toast.error(filesUploadValidationTypes[validationId].limitMessage);
              } else {
                toast.error(error);
              }
            } else if (response?.payload?.Message?.startsWith?.('Authorization has been denied')) {
              toast.error(SESSION_HAS_ENDED_ERROR_MESSAGE);
            } else {
              toast.error(GLOBAL_ERROR_MESSAGE);
            }
          } catch {
            toast.error(GLOBAL_ERROR_MESSAGE);
          }
        })
      );
      this.setState({ loaded: [] });
      endLoadingBar(this.LoadingBar);

      return toCamel(
        uploadedFiles.map((file) => ({
          ...file,
          imageUrl: file.OriginalImageUri,
          thumbnailUrl: file.ThumbnailImageUri
        }))
      );
    };

  onLoaderFinish = () => {
    this.setState({ uploadProgress: 0, startProgress: false });
  };

  onUpdateSortOrder = async (body, isThumbnailUpdate) => {
    startLoadingBar(this.LoadingBar);

    await updateSortOrder(
      this.state.registrationData.CatalogService,
      this.state.registrationData.AccessToken,
      this.props.match.params.sharedKey,
      this.state.registrationData.ActorId,
      toPascal(
        body.map((attachment) => ({
          sortOrder: attachment.sortOrder,
          actorAttachmentId: attachment.attachmentId
        }))
      ),
      this.props.match.params.profileId
    );

    if (isThumbnailUpdate) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await useImageAsThumbnail(
        this.state.registrationData.CatalogService,
        this.state.registrationData.AccessToken,
        this.props.match.params.sharedKey,
        this.state.registrationData.ActorId,
        body[0].attachmentId,
        this.props.match.params.profileId
      );
    }
    endLoadingBar(this.LoadingBar);
  };

  onImageDelete = async (attachmentId) => {
    startLoadingBar(this.LoadingBar);

    await deleteImage(
      this.state.registrationData.CatalogService,
      this.state.registrationData.AccessToken,
      this.props.match.params.sharedKey,
      this.state.registrationData.ActorId,
      attachmentId,
      this.props.match.params.profileId
    );

    endLoadingBar(this.LoadingBar);
  };

  onImageCrop = async (crop) => {
    startLoadingBar(this.LoadingBar);

    const response = await editImage(
      this.state.registrationData.CatalogService,
      this.state.registrationData.AccessToken,
      this.props.match.params.sharedKey,
      this.state.registrationData.ActorId,
      toPascal(crop),
      this.props.match.params.profileId
    );

    endLoadingBar(this.LoadingBar);

    if (response && response.status === 200) {
      if (response.payload.Successes && response.payload.Successes.length) {
        var success = response.payload.Successes[0];
        toast.success(success);
      }

      if (response.payload.Errors && response.payload.Errors.length) {
        var error = response.payload.Errors[0];
        toast.error(error);
      } else {
        return toCamel({
          ...response.payload,
          attachmentId: response.payload.ActorAttachmentId,
          imageUrl: response.payload.OriginalImageUri,
          thumbnailUrl: response.payload.ThumbnailImageUri
        });
      }
    }
  };

  onVideoAdd = async (videoUrl) => {
    const { match, isPreview } = this.props;
    if (isPreview) {
      return { videoUrl };
    }

    startLoadingBar(this.LoadingBar);

    const response = await uploadVideo(match.params.sharedKey, match.params.profileId, {
      ActorId: this.state.registrationData.ActorId,
      FileName: videoUrl
    });

    endLoadingBar(this.LoadingBar);

    if (response && response.status === 200) {
      if (response.payload.Successes && response.payload.Successes.length) {
        var success = response.payload.Successes[0];
        toast.success(success);
      }

      if (response.payload.Errors && response.payload.Errors.length) {
        var error = response.payload.Errors[0];
        toast.error(error);
      } else {
        return {
          ...response.payload.Data,
          videoUrl: response.payload.Data.FileName
        };
      }
    }

    return;
  };

  render() {
    const { registrationData, registeredSuccessfully, registrationSuccessData } = this.state;
    if (!registrationData) {
      return <AppLoadingBar onRef={(ref) => (this.LoadingBar = ref)} />;
    }

    if (registeredSuccessfully) {
      return (
        <>
          <RegistrationSuccess
            {...this.props}
            message={registrationSuccessData.SuccessMessage}
            buttonText={registrationSuccessData.RegisterAgainButton}
            buttonCallback={this.resetForm}
          />
          <AppLoadingBar onRef={(ref) => (this.LoadingBar = ref)} />
        </>
      );
    }

    return (
      <>
        <CompanyNameContext.Consumer>
          {(context) =>
            context.companyName !== registrationData.CompanyName &&
            context.setCompanyNameAndLogo(registrationData.CompanyName, registrationData.CompanyLogoUrl)
          }
        </CompanyNameContext.Consumer>
        <div className="register">
          <Panel noBorder={this.props.match.params.embed === 'embed'}>
            <CastingForm
              castingFormData={toCamel(registrationData)}
              match={this.props.match}
              onSubmit={this.submit}
              onPhotoSelect={this.onPhotoSelect}
              isPreview={this.props.isPreview}
              onImageCrop={this.onImageCrop}
              onUpdateSortOrder={this.onUpdateSortOrder}
              onDelete={this.onImageDelete}
              recaptchaRef={this.recaptchaRef}
              reCaptchaChanged={this.reCaptchaChanged}
              ref={this.formik}
              onVideoAdd={this.onVideoAdd}
              showEmailDuplicationMessage={this.state.showEmailValidationError}
              isSubmitting={this.state.isSubmitting}
            />
          </Panel>
          <AppLoadingBar
            onRef={(ref) => (this.LoadingBar = ref)}
            progress={this.state.uploadProgress}
            startProgress={this.state.startProgress}
            onLoaderFinish={this.onLoaderFinish}
          />
        </div>
      </>
    );
  }
}

RegistrationForm.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  resetForm: PropTypes.func,
  isPreview: PropTypes.bool,
  captchaToken: PropTypes.string
};

export default RegistrationForm;
